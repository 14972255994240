import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, CircularProgress, Button } from '@material-ui/core';
import BalanceMenu from 'components/menus/BalanceMenu';
import { formatCurrency } from 'utils/currency';
import TextInput from 'components/inputs/TextInput';
import { fetchCard, replaceCard } from 'actions/CardActions';

export class ReplaceCardPage extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchCard(this.props.match.params.id);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.replaceCard(this.props.match.params.id, data, this.formRef);
  }

  render() {
    const { isFetching, isProcessing, card } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <BalanceMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Replace Card</Typography>

            <Typography align="center" className="below-space" gutterBottom>
              This will replace the following card.
              The current balance of the card will be taken off and transferred to the new card.
              The new card is the replacement card number entered below.
              This action cannot be undone.
            </Typography>

            {isFetching &&
              <Grid container justify="center" className="top-space">
                <CircularProgress />
              </Grid>
            }
            {card &&
              <React.Fragment>
                <Typography variant="h6" align="center" gutterBottom>
                  Card ID: {card.card_id}
                  <br />
                  Product Name: {card.product_name}
                </Typography>

                <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef}>
                  <Grid container spacing={8} justify="center">
                    <Grid item xs={12} md={8}>
                      <TextInput name='replacement_card_number' label='Replacement Card Number' required
                        hint="11 digit card ID of the new card"
                        validations={{isLength: 11}}
                        validationErrors={{
                          isLength: 'must be 11 characters',
                          isDefaultRequiredValue: "can't be blank"
                        }}
                      />
                      <TextInput name='replacement_reason' label='Replacement Reason' required
                        validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                      />
                      <div className='button-container'>
                        <Button variant='contained' onClick={() => this.props.navigateTo('/cards/' + card.id)}>Cancel</Button>
                        <Button type='submit' variant='contained' color='secondary' disabled={isProcessing}>Replace</Button>
                      </div>
                    </Grid>
                  </Grid>
                </Formsy>
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.card.isFetching,
    isProcessing: state.card.isProcessing,
    card: state.card.card
  }
}

const mapDispatchToProps = {
  fetchCard: (id) => fetchCard(id),
  replaceCard: (id, data, form) => replaceCard(id, data, form),
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceCardPage);
