export function mobileNumberValidation(locale) {
  console.log(locale)
  switch (locale) {
  case 'AU':
    return 'auMobileNumber';
  case 'en-GB':
    return 'ukMobileNumber';
  default:
    return 'auMobileNumber';
  }
}
