import axios from 'axios';
import { flashMessage } from 'redux-flash';
import { push } from 'connected-react-router';

export const FETCH_CARD_REQUEST = 'FETCH_CARD_REQUEST';
export const FETCH_CARD_SUCCESS = 'FETCH_CARD_SUCCESS';
export const FETCH_CARD_FAILURE = 'FETCH_CARD_FAILURE';
export const UPDATE_CARD_REQUEST = 'UPDATE_CARD_REQUEST';
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS';
export const UPDATE_CARD_FAILURE = 'UPDATE_CARD_FAILURE';

function fetchCardRequest() {
  return {
    type: FETCH_CARD_REQUEST
  }
}

function fetchCardSuccess(card) {
  return {
    type: FETCH_CARD_SUCCESS,
    card
  }
}

function fetchCardFailure() {
  return {
    type: FETCH_CARD_FAILURE
  }
}

function updateCardRequest() {
  return {
    type: UPDATE_CARD_REQUEST
  }
}

function updateCardSuccess() {
  return {
    type: UPDATE_CARD_SUCCESS
  }
}

function updateCardFailure() {
  return {
    type: UPDATE_CARD_FAILURE
  }
}

export function fetchCard(id) {
  return(dispatch) => {
    dispatch(fetchCardRequest());
    return axios.get('cards/' + id)
      .then(response => {
        dispatch(fetchCardSuccess(response.data.card));
      }).catch(error => {
        dispatch(fetchCardFailure());
        dispatch(flashMessage('Card not found', {isError: true}));
        dispatch(push('/transaction_search'));
      });
  }
}

export function updateCard(id, data) {
  return(dispatch) => {
    dispatch(updateCardRequest());
    return axios.put('cards/' + id, {card: data})
      .then(response => {
        dispatch(updateCardSuccess());
        dispatch(push('/cards/' + id));
      }).catch(error => {
        dispatch(updateCardFailure());
        dispatch(flashMessage('Error updating card', {isError: true}));
      });
  }
}

export function activateCard(card_id) {
  return(dispatch) => {
    return axios.put('cards/' + card_id + '/activate')
      .then(response => {
        dispatch(flashMessage('Card successfully queued for activation'));
        window.scrollTo(0, 0);
      }).catch(error => {
        dispatch(flashMessage('Error activating card', {isError: true}));
        window.scrollTo(0, 0);
      });
  }
}

export function replaceCard(id, data, form) {
  return(dispatch) => {
    dispatch(updateCardRequest());
    return axios.post('cards/' + id + '/replace', {card: data})
      .then(response => {
        dispatch(updateCardSuccess());
        dispatch(flashMessage('Card successfully queued for replacement'));
        dispatch(push('/cards/' + id));
      }).catch(error => {
        dispatch(updateCardFailure());
        dispatch(flashMessage('Error replacing card', {isError: true}));
        if (error.response.data.errors) {
          form.current.updateInputsWithError(error.response.data.errors);
        }
      });
  }
}

export function cancelCard(card_id) {
  return(dispatch) => {
    return axios.put('cards/' + card_id + '/cancel')
      .then(response => {
        dispatch(flashMessage('Card cancelled successfully'));
        window.scrollTo(0, 0);
      }).catch(error => {
        dispatch(flashMessage('Error cancelling card', {isError: true}));
        window.scrollTo(0, 0);
      });
  }
}

export function resendCard(card_id) {
  return(dispatch) => {
    return axios.post('cards/' + card_id + '/resend')
      .then(response => {
        dispatch(flashMessage('Card has been successfully queued to resend'));
        window.scrollTo(0, 0);
      }).catch(error => {
        dispatch(flashMessage('Error resending card', {isError: true}));
        window.scrollTo(0, 0);
      });
  }
}
