import { addValidationRule } from 'formsy-react';
import _ from 'lodash';
import moment from 'moment';

addValidationRule('isGreaterThan', function (_values, value, compareValue) {
  return Number(value) > compareValue;
});

addValidationRule('isLessThan', function (_values, value, compareValue) {
  return Number(value) < compareValue;
});

addValidationRule('isGreaterEqualTo', function (_values, value, compareValue) {
  return Number(value) >= compareValue;
});

addValidationRule('isLesserEqualTo', function (_values, value, compareValue) {
  return Number(value) <= compareValue;
});

addValidationRule('isGreaterEqualToField', function (values, value, compareValue) {
  return Number(value) >= values[compareValue];
});

addValidationRule('notIn', function (_values, value, compareValues) {
  return !_.includes(compareValues, value);
});

addValidationRule('dateSameOrAfter', function (values, value, compareValue) {
  return moment(value, 'DD/MM/YYYY').isSameOrAfter(moment(values[compareValue], 'DD/MM/YYYY'));
});

addValidationRule('timeSameOrAfter', function (values, value, compareValue) {
  return moment(value, 'DD/MM/YYYY H:mm').isSameOrAfter(moment(values[compareValue], 'DD/MM/YYYY H:mm'));
});

// Validate month is in future with corresponding year field. Useful for credit card expiries
addValidationRule('futureMonth', function (values, value, yearField) {
  if (!values[yearField]) return true;

  var futureYear = values[yearField] > moment().year();
  var futureMonth = (values[yearField] == moment().year()) && value >= moment().month() + 1;
  return futureYear || futureMonth;
});

addValidationRule('futureYear', function (values, value) {
  return value >= moment().year();
});

// Validate 10 digit number starting with 04, no spaces
addValidationRule('auMobileNumber', function (values, value) {
  return /^(04)\d{8}$/.test(value);
});

// Validate 10 digit number starting with 04, no spaces
addValidationRule('ukMobileNumber', function (values, value) {
  return /^\d{10,11}$/.test(value);
});

addValidationRule('isCommaSeparatedEmails', function (_values, value) {
  return value.split(',').every(function (email) {
    return email.trim().match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  });
});
