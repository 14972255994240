import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import BalanceMenu from 'components/menus/BalanceMenu';
import CardDetails from 'components/CardDetails';
import { fetchCard } from 'actions/CardActions';

export class ViewCardPage extends React.Component {
  componentDidMount() {
    this.props.fetchCard(this.props.match.params.id);
  }

  // To handle case where navigating from one card to another, which doesn't remount but changes the ID in the URL
  componentDidUpdate(prevProps) {
    if (this.props.match.params.id != prevProps.match.params.id) {
      this.props.fetchCard(this.props.match.params.id);
    }
  }

  render() {
    const { webposProfile, isFetching, card } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <BalanceMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" gutterBottom>Card Details</Typography>

            {isFetching &&
              <Grid container justify="center" className="top-space">
                <CircularProgress />
              </Grid>
            }

            {card && <CardDetails card={card} webposProfile={webposProfile} />}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    isFetching: state.card.isFetching,
    card: state.card.card
  }
}

const mapDispatchToProps = {
  fetchCard: (data) => fetchCard(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCardPage);
