import {
  FETCH_CARD_REQUEST,
  FETCH_CARD_SUCCESS,
  FETCH_CARD_FAILURE,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAILURE
} from 'actions/CardActions';
import { FETCH_BALANCE_SUCCESS } from 'actions/BalanceActions';

const initialState = {
  isFetching: false,
  isProcessing: false,
  card: null
};

export default function card(state = initialState, action) {
  switch(action.type) {
    case FETCH_CARD_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case FETCH_CARD_SUCCESS :
    case FETCH_BALANCE_SUCCESS : {
      return Object.assign({}, state, {
        isFetching: false,
        card: action.card
      });
    }
    case FETCH_CARD_FAILURE : {
      return Object.assign({}, state, {
        isFetching: false,
        card: null
      });
    }
    case UPDATE_CARD_REQUEST : {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case UPDATE_CARD_SUCCESS :
    case UPDATE_CARD_FAILURE : {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }

    default:
      return state;
  }
}
