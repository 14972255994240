import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, InputAdornment, CircularProgress, Button } from '@material-ui/core';
import BalanceMenu from 'components/menus/BalanceMenu';
import CardDetails from 'components/CardDetails';
import TextInput from 'components/inputs/TextInput';
import queryString from 'query-string';
import { parseCardID } from 'utils/card-swipes';
import { fetchBalance } from 'actions/BalanceActions';

export class BalancePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: queryString.parse(this.props.location.search).card_id
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.cardNumber) {
      this.props.fetchBalance(this.state.cardNumber, this.formRef);
    }
  }

  onChange(event) {
    this.setState({cardNumber: event.target.value});
    const parsedNumber = parseCardID(event.target.value);

    if (parsedNumber) {
      this.setState({cardNumber: parsedNumber});
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.fetchBalance(data.card_number, this.formRef);
  }

  render() {
    const { webposProfile, isFetching, card } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <BalanceMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" className="hidden-print" gutterBottom>Balance Check</Typography>

            <Formsy onValidSubmit={this.handleSubmit} ref={this.formRef} className="full-width hidden-print">
              <Grid container spacing={8} justify="center">
                <Grid item md={6} xs={12}>
                  <TextInput name="card_number" label="Card Number" autoFocus required
                    value={this.state.cardNumber}
                    hint="Enter 11 digit Card ID or 19 digit Card Number"
                    inputProps={{
                      endAdornment: isFetching ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                    }}
                    onChange={this.onChange}
                    validationErrors={{isDefaultRequiredValue: "can't be blank"}}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16} justify="center">
                <Grid item sm={10} md={6} className="full-width below-space">
                  <Button type='submit' variant='contained' fullWidth color='secondary' disabled={isFetching}>Check Balance</Button>
                </Grid>
              </Grid>
            </Formsy>

            {card &&
              <React.Fragment>
                <CardDetails card={card} webposProfile={webposProfile} showBalance />

                <div className="button-container">
                  <Button variant="contained" color="secondary" className="hidden-print" onClick={() => window.print()}>Print Report</Button>
                </div>
              </React.Fragment>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    isFetching: state.balance.isFetching,
    card: state.card.card
  }
}

const mapDispatchToProps = {
  fetchBalance: (data, form) => fetchBalance(data, form)
};

export default connect(mapStateToProps, mapDispatchToProps)(BalancePage)
