import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Button } from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';

export class PasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  render() {
    const { handleSubmit, isProcessing } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleSubmit} ref={this.formRef}>
          <TextInput type='password' name='password' label='New password' required
            validations="minLength:8"
            validationErrors={{
              isDefaultRequiredValue: "can't be blank",
              minLength: 'must be more than 8 characters'
            }}
          />
          <TextInput type='password' name='password_confirmation' label='Confirm new password' required
            validations="minLength:8,equalsField:password"
            validationErrors={{
              isDefaultRequiredValue: "can't be blank",
              minLength: 'must be more than 8 characters',
              equalsField: 'must match password'
            }}
          />
          <div className='button-container'>
            <Button type='submit' disabled={isProcessing} variant='contained' color='secondary'>Submit</Button>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    isProcessing: state.session.isProcessing,
    currentUser: state.session.currentUser
  }
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm)
