import { combineReducers } from 'redux';
import { reducer as FlashReducer } from 'redux-flash';
import ProfileReducer from './ProfileReducer';
import SessionReducer from './SessionReducer';
import LoadReducer from './LoadReducer';
import BalanceReducer from './BalanceReducer';
import CardReducer from './CardReducer';
import UserReducer from './UserReducer';
import ReportReducer from './ReportReducer';
import InvoiceReducer from './InvoiceReducer';
import TransactionReducer from './TransactionReducer';
import DigitalPartnerReducer from './DigitalPartnerReducer';
import ProductReducer from './ProductReducer';

const rootReducer = combineReducers({
  flash: FlashReducer,
  session: SessionReducer,
  profile: ProfileReducer,
  load: LoadReducer,
  balance: BalanceReducer,
  card: CardReducer,
  user: UserReducer,
  report: ReportReducer,
  invoice: InvoiceReducer,
  transaction: TransactionReducer,
  digitalPartner: DigitalPartnerReducer,
  product: ProductReducer
});

export default rootReducer;
