import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Grid, Paper, Typography, CircularProgress, Button } from '@material-ui/core';
import BalanceMenu from 'components/menus/BalanceMenu';
import TextInput from 'components/inputs/TextInput';
import { mobileNumberValidation } from 'utils/locales';
import { fetchCard, updateCard } from 'actions/CardActions';

export class EditCardPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchCard(this.props.match.params.id);
  }

  handleSubmit(data, _data, _invalidateForm) {
    this.props.updateCard(this.props.match.params.id, data);
  }

  render() {
    const { isFetching, isProcessing, card, webposProfile } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item md={2} className='full-width'>
          <BalanceMenu />
        </Grid>
        <Grid item md={8} className="full-width">
          <Paper className="form-container" elevation={1}>
            <Typography variant="h4" align="center" className="hidden-print" gutterBottom>Edit Card</Typography>

            {isFetching &&
              <Grid container justify="center" className="top-space">
                <CircularProgress />
              </Grid>
            }
            {card &&
              <Formsy onValidSubmit={this.handleSubmit}>
                <Grid container spacing={8} justify="center">
                  <Grid item xs={12} md={8}>
                    {card.delivery_email &&
                      <TextInput name='delivery_email' label='Recipient Email' required
                        value={card.delivery_email}
                        validations="isEmail"
                        validationErrors={{
                          isEmail: 'not a valid email',
                          isDefaultRequiredValue: "can't be blank"
                        }}
                      />
                    }
                    {card.delivery_sms_number &&
                      <TextInput name='delivery_sms_number' label='Recipient SMS Number' required
                        value={card.delivery_sms_number} hint={'For SMS delivery' + (webposProfile.primary_locale == 'AU' ? '(Australian numbers only)' : '')}
                        validations={mobileNumberValidation(webposProfile.primary_locale)}
                        validationErrors={{
                          auMobileNumber: 'must be a valid mobile number in the format 04XXXXXXXX',
                          ukMobileNumber: 'must be 10-11 digits',
                          isDefaultRequiredValue: "can't be blank"
                        }}
                      />
                    }
                    <div className='button-container'>
                      <Button variant='contained' onClick={() => this.props.navigateTo('/cards/' + card.id)}>Cancel</Button>
                      <Button type='submit' variant='contained' color='secondary' disabled={isProcessing}>Update</Button>
                    </div>
                  </Grid>
                </Grid>
              </Formsy>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    webposProfile: state.session.webposProfile,
    isFetching: state.card.isFetching,
    isProcessing: state.card.isProcessing,
    card: state.card.card
  }
}

const mapDispatchToProps = {
  fetchCard: (id) => fetchCard(id),
  updateCard: (id, data) => updateCard(id, data),
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCardPage);
