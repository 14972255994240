import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Grid, Typography, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import { formatCurrency } from 'utils/currency';
import { formatDateTime } from 'utils/dates';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { activateCard, resendCard, cancelCard } from 'actions/CardActions';
import { fetchBalance } from 'actions/BalanceActions';

export class CardDetails extends React.Component {
  renderTableItem(label, value) {
    return (
      value ?
        <TableRow>
          <TableCell padding="dense">{label}</TableCell>
          <TableCell padding="dense">{value}</TableCell>
        </TableRow>
      :
        null
    );
  }

  render() {
    const { card, webposProfile, showBalance, balanceFetching } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={8} className="top-space below-space">
          <Grid item md={6} xs={12}>
            <Table>
              <TableBody>
                {this.renderTableItem('ID', card.card_id)}
                {this.renderTableItem('Type', _.startCase(card.medium))}
                {card.created_at && this.renderTableItem('Purchase Date', formatDateTime(card.created_at))}
                {this.renderTableItem('Recipient SMS Number', card.delivery_sms_number)}
                {this.renderTableItem('Recipient Email', card.delivery_email_address)}
                {this.renderTableItem('Status', _.startCase(card.status))}
              </TableBody>
            </Table>
            {card.replaced &&
              <div className="top-space">
                <Typography variant="h6" className="inline">{'Replaced with card number: '}
                  {card.include_replacement_link ?
                    <NavLink to={'/cards/' + card.replacement_id}>{card.replacement_number}</NavLink>
                  :
                    card.replacement_number
                  }
                </Typography>
              </div>
            }
          </Grid>
          <Grid item md={2} xs={12} />
          <Grid item md={3} xs={12}>
            <img className="full-width hidden-print" src={card.product_image} />
          </Grid>
        </Grid>
        {balanceFetching &&
          <Grid container justify="center" className="top-space">
            <CircularProgress />
          </Grid>
        }
        {card.balance != null &&
          <Grid container spacing={8} justify="center" className="top-space">
            <Grid item md={4} xs={12} align="center">
              <Typography variant="subtitle1">Balance</Typography>
              <Typography variant="h4" color="primary">{formatCurrency(card.balance, webposProfile.currency_code)}</Typography>
            </Grid>
            <Grid item md={4} xs={12} align="center">
              <Typography variant="subtitle1">Expiry Date</Typography>
              <Typography variant="h4" color="primary">{card.expiry}</Typography>
            </Grid>
          </Grid>
        }

        {card.id &&
          <div className="hidden-print">
            <Typography variant="h5" gutterBottom className="top-space">Actions</Typography>
            <div className="button-container below-space" style={{textAlign: 'left'}}>
              {card.balance == null && card.card_id &&
                <Button variant="contained" color="primary" disabled={balanceFetching} onClick={() => this.props.fetchBalance(card.card_id)}>Check Balance</Button>
              }
              {card.status == 'activation_pending' &&
                <Button variant="contained" color="secondary" onClick={() => this.props.activateCard(card.id)}>Activate now</Button>
              }
              {card.replaceable &&
                <Button variant="contained" onClick={() => {
                    if (window.confirm('Are you sure you want to replace this card?')) {
                      this.props.navigateTo('/cards/' + card.id + '/replace')
                    }
                  }}
                >
                  Replace Card
                </Button>
              }
              {card.medium == 'digital' && (card.delivery_sms_number || card.delivery_email) &&
                <Button variant="contained" onClick={() => this.props.navigateTo('/cards/' + card.id + '/edit')}>Edit</Button>
              }
              {card.status == 'activated' && (card.delivery_sms_number || card.delivery_email) &&
                <Button variant="contained" onClick={() => {
                    if (window.confirm('Are you sure you want to resend this card?')) {
                      this.props.resendCard(card.id);
                    }
                  }}
                >
                  Resend
                </Button>
              }
              {card.cancellable &&
                <Button variant="contained" color="secondary" className="hidden-print" onClick={() => this.props.cancelCard(card.id)}>Cancel Card</Button>
              }
            </div>
          </div>
        }

        {card.original_transaction &&
          <React.Fragment>
            <Typography variant="h5" gutterBottom className="top-space">Original Purchase</Typography>
            <Table className="below-space">
              <TableHead>
                <TableRow>
                  <TableCell padding="dense">ID</TableCell>
                  <TableCell padding="dense">Date/Time</TableCell>
                  <TableCell padding="dense">Type</TableCell>
                  <TableCell padding="dense">Payment Method</TableCell>
                  <TableCell align="right" padding="dense">Total Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell padding="dense">
                    <NavLink to={'/purchases/' + card.original_transaction.id}>{card.original_transaction.id}</NavLink>
                  </TableCell>
                  <TableCell padding="dense">{formatDateTime(card.original_transaction.date)}</TableCell>
                  <TableCell padding="dense">{card.original_transaction.type}</TableCell>
                  <TableCell padding="dense">{card.original_transaction.payment_method}</TableCell>
                  <TableCell align="right" padding="dense">{formatCurrency(card.original_transaction.total_paid, webposProfile.currency_code)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </React.Fragment>
        }

        {card.transactions &&
          <React.Fragment>
            <Typography variant="h5" gutterBottom className="top-space">Card History</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="dense">Date/Time</TableCell>
                  <TableCell padding="dense">Type</TableCell>
                  <TableCell padding="dense">Description</TableCell>
                  <TableCell align="right" padding="dense">Amount</TableCell>
                  {card.transactions[0] && card.transactions[0].balance &&
                    <TableCell align="right" padding="dense">Balance</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {card.transactions.map((transaction, index) =>
                  <TableRow key={index}>
                    <TableCell padding="dense">{formatDateTime(transaction.date)}</TableCell>
                    <TableCell padding="dense">{transaction.type}</TableCell>
                    <TableCell padding="dense">{transaction.description}</TableCell>
                    <TableCell align="right" padding="dense">{formatCurrency(transaction.amount, webposProfile.currency_code)}</TableCell>
                    {card.transactions[0] && card.transactions[0].balance &&
                      <TableCell align="right" padding="dense">{formatCurrency(transaction.balance, webposProfile.currency_code)}</TableCell>
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    balanceFetching: state.balance.isFetching
  }
}

const mapDispatchToProps = {
  activateCard: (card_id) => activateCard(card_id),
  cancelCard: (card_id) => cancelCard(card_id),
  resendCard: (card_id) => resendCard(card_id),
  fetchBalance: (data) => fetchBalance(data),
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);
